import { Header, NewsWidget, QuickLinks } from "@prmf/site-components";
import { routes } from "@prmf/site-router";
import { Link } from "@remix-run/react";

const NotFound: React.FC = () => {
  return (
    <div>
      <Header
        title="Page Not Found"
        image="/media/assets/accreditations-header.jpg"
      >
        <p>Sorry, we couldn't find the page you were looking for.</p>
        <p>
          Please try the other pages of our website listed below, or, if you
          think there's been an error, don't hesitate to{" "}
          <Link className="underline" to={routes.contact}>
            contact us
          </Link>
          .
        </p>
      </Header>
      <QuickLinks />
      <NewsWidget />
    </div>
  );
};

export default NotFound;
